import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import { tagToPWImage, useTags } from 'src/hooks/api'
import Loading from 'src/components/Loading/Loading'
import TagEntry from './TagEntry'
import './Tags.sass'

const Tags = () => {
  const tags = useTags()
  const entries = tags.map(tagToPWImage)

  return (
    <>
      <PhotoWall
        className="photos tags"
        entries={entries}
        ImageComponent={TagEntry}
      />
    </>
  )
}

const TagsWrapper = () => {
  const isLoggedIn = useSelector((s: any) => !!s.user)
  if (!isLoggedIn) return <Redirect to="/" />

  return (
    <Suspense fallback={<Loading className="photos tags" />}>
      <Tags />
    </Suspense>
  )
}

export default TagsWrapper

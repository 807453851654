import React from 'react'
import { FormattedMessage } from 'react-intl'
import { albumToPWImage, useSearchTerms } from 'src/hooks/api'
import { albumMatch, tagMatch } from './searchMatch'
import AlbumResult from './AlbumResult'
import TagResult from './TagResult'

interface SearchResultsProps {
  q: string
  setQ: (q: string) => void
}

const SearchResults = ({ q, setQ }: SearchResultsProps) => {
  const searchTerms = useSearchTerms()
  const albums = searchTerms.albums.filter(albumMatch(q)).map(albumToPWImage)
  const tags = searchTerms.tags.filter(tagMatch(q))
  const total = albums.length + tags.length
  const handleSelect = () => {
    (document.activeElement as any)?.blur?.()
    setQ('')
  }

  if (total === 0) {
    return (
      <div className="no-results">
        <FormattedMessage id="search.no-results" />
      </div>
    )
  } else {
    return <>
      {tags.map(r =>
        <TagResult key={r.slug} entry={r} onSelect={handleSelect} />
      )}
      {albums.map(r =>
        <AlbumResult key={r.slug} entry={r} onSelect={handleSelect} />
      )}
    </>
  }
}

export default SearchResults

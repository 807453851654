import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import useDragEvents from 'src/hooks/useDragEvents'
import { Image } from 'src/components/PhotoWall/types'
import ImageActions from './ImageActions'
import { imageFromFile, pickImages } from 'src/util/image'
import './Canvas.sass'

const Canvas = () => {
  const [entries, setEntries] = useState<Image[]>([])
  const handleFiles = async (files: File[]) => {
    for (const file of files) {
      const img = await imageFromFile(file)
      setEntries(l => [...l, img])
    }
  }

  const [events, isDragging] = useDragEvents(handleFiles)
  let className = 'canvas photos'
  if (isDragging) className += ' dragging'
  if (!entries.length) className += ' empty'

  if (!entries.length) {
    return (
      <div className={className} {...events}>
        <div className="message" onClick={() => pickImages(handleFiles)}>
          <FormattedMessage id="drop-files" />
        </div>
      </div>
    )
  }

  const remove = n => {
    const copy = entries.slice()
    copy.splice(n, 1)
    setEntries(copy)
  }
  const swap = (a, b) => {
    const copy = entries.slice()
    const t = copy[b]
    copy[b] = copy[a]
    copy[a] = t
    setEntries(copy)
  }

  return (
    <PhotoWall
      className={className}
      {...events}
      entries={entries}
      imageClassName="with-actions"
      imageChildren={<ImageActions remove={remove} swap={swap} />}
    />
  )
}

export default Canvas

import { AlbumWithTags, Tag } from "src/types"

const textMatch = (words: string[], fulltext: string): boolean => {
  return words.every(word => {
    if (word.startsWith('-')) {
      return !fulltext.includes(word.substr(1))
    } else {
      return fulltext.includes(word)
    }
  })
}

export const albumMatch = (q: string) => {
  const words = q.toLowerCase().split(' ')
  return (entry: AlbumWithTags) => {
    const fulltext = [
      entry.title.toLowerCase(),
      ...entry.tags?.map(t => [
        t.title.toLowerCase(),
        t.slug.toLowerCase()
      ].join(' ')) || []
    ].join(' ')
    return textMatch(words, fulltext)
  }
}

export const tagMatch = (q: string) => {
  const words = q.toLowerCase().split(' ')
  return (entry: Tag) => {
    const fulltext = [
      entry.title.toLowerCase(),
      entry.slug.toLowerCase()
    ].join(' ')
    return textMatch(words, fulltext)
  }
}

import React from 'react'
import { FormattedDate } from 'react-intl'
import { useParams, Link } from 'react-router-dom'
import { useAlbum } from 'src/hooks/api'
import AlbumMeta from './AlbumMeta'

const AlbumInfo = () => {
  const { slug } = useParams()
  const album = useAlbum(slug)?.album

  return (
    <div className="album-info">
      <h1>
        {album?.title}
      </h1>
      <FormattedDate
        value={album?.date}
        day="numeric"
        month="short"
        year="numeric"
      />
      {album?.tags &&
        <div className="tags">
          {album.tags.map(tag =>
            <Link key={tag.slug} to={'/tag/' + tag.slug} className="tag">{tag.title}</Link>
          )}
        </div>
      }
      <AlbumMeta />
    </div>
  )
}

export default AlbumInfo

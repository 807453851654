import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ImageProps } from 'src/components/PhotoWall/Image'
import { Image } from 'src/components/PhotoWall/types'
import { Tag } from 'src/types'
import './TagEntry.sass'



const TagEntry: React.FC<ImageProps<Tag & Image>> = ({ style, entry }) => {
  const history = useHistory()
  const fullStyle = {
    ...style,
    backgroundImage: `url("${entry.url}")`
  }
  const cls = 'image tag-entry tag-type-' + entry.type
  const handleClick = e => {
    if (entry.href) {
      e.preventDefault()
      history.push(entry.href)
    }
  }

  return (
    <a className={cls} style={fullStyle} onClick={handleClick} href={entry.href}>
      <div className="title">{entry.title}</div>
      <div className="subtitle"><FormattedMessage id="search.tag-albums" values={{n: entry.albums}} /></div>
    </a>
  )
}

export default TagEntry

import React, { CSSProperties } from 'react'
import { FormattedDate } from 'react-intl'
import { Link } from 'react-router-dom'
import { AlbumWithTags } from 'src/types'
import { Image } from '../PhotoWall/types'

interface AlbumResultProps {
  entry: AlbumWithTags & Image
  onSelect?: () => void
}

const AlbumResult = ({ entry, onSelect }: AlbumResultProps) => {
  const style: CSSProperties = {}
  if (entry.cover) {
    style.backgroundImage = `url("${entry.url}")`
  }
  return (
    <Link className="search-result album" to={entry.href} onClick={onSelect}>
      <div className="preview" style={style}/>
      <div className="info">
        <div className="title">
          {entry.title}
        </div>
        <div className="subtitle">
          <FormattedDate
            value={entry.date}
            day="numeric"
            month="short"
            year="numeric"
          />

        </div>
      </div>
    </Link>
  )
}

export default AlbumResult

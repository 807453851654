import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { registerModal, useModal } from '@gluedigital/modal'
import './Login.sass'
import Loading from 'src/components/Loading/Loading'

interface LoginState {
  isLoading?: boolean
  error?: Error
}

const Login = () => {
  const [data, setData] = useState({ username: '', password: '' })
  const [state, setState] = useState<LoginState>({})
  const dispatch = useDispatch()
  const modal = useModal()

  const handleSubmit = async e => {
    e.preventDefault()
    setState({ isLoading: true })
    const url = (process.env.APP_URL || 'http://localhost:3000') + '/api/login'
    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
    const payload = await res.json()
    if (res.ok) {
      dispatch({ type: 'user/login', payload })
      modal.hide()
    } else {
      setState({ error: payload?.error || 'Unknown error' })
    }
  }

  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value })

  return (
    <form onSubmit={handleSubmit}>
      <h1><FormattedMessage id="login.title" /></h1>
      <label>
        <span><FormattedMessage id="login.username" /></span>
        <input
          name="username"
          value={data.username}
          onChange={handleChange}
          required
          disabled={state.isLoading}
        />
      </label>
      <label>
        <span><FormattedMessage id="login.password" /></span>
        <input
          name="password"
          type="password"
          value={data.password}
          onChange={handleChange}
          required
          disabled={state.isLoading}
        />
      </label>
      <button disabled={state.isLoading}>
        {state.isLoading ? <Loading className="tiny" /> : <FormattedMessage id="login.submit" />}
      </button>
      {state.error &&
        <p className="error">{state.error.message}</p>
      }
    </form>
  )
}


registerModal('login', Login)

import React from 'react'
import { useHistory } from 'react-router-dom'
import { Image as ImageType } from './types'

export interface ImageProps<T extends ImageType> {
  style: React.CSSProperties
  entry: T
  className?: string
  children?: React.ReactNode
  [other: string]: any
}

const Image = <T extends ImageType> ({ style, entry, className, children, ...props }: ImageProps<T>) => {
  const history = useHistory()
  const fullStyle = {
    ...style,
    backgroundImage: `url("${entry.url}")`
  }
  const cls = 'image' + (className ? ' ' + className : '')
  const handleClick = e => {
    if (entry.href) {
      e.preventDefault()
      history.push(entry.href)
    }
  }

  return (
    <a className={cls} style={fullStyle} onClick={handleClick} href={entry.href}>
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child, { entry, ...props } as any)
          : child
      )}
    </a>
  )
}

export default Image

import React, { useState } from 'react'
import { FileHandler } from 'src/util/image'

interface DragEvents {
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void
  onDragEnter: (e: React.DragEvent<HTMLDivElement>) => void
  onDragLeave: (e: React.DragEvent<HTMLDivElement>) => void
}

const useDragEvents = (handler: FileHandler): [DragEvents, boolean] => {
  const [dragging, setDragging] = useState(0)
  const dragEvents: DragEvents = {
    onDrop: e => {
      setDragging(0)
      e.preventDefault()
      handler(e.dataTransfer.files)
    },
    onDragOver: e => e.preventDefault(),
    onDragEnter: () => setDragging(n => n + 1),
    onDragLeave: () => setDragging(n => n - 1)
  }

  return [dragEvents, dragging !== 0]
}

export default useDragEvents

import React, { HtmlHTMLAttributes } from 'react'
import useWidth from 'src/hooks/useWidth'
import { Image } from './types'
import Row from './Row'
import './PhotoWall.sass'
import useImagesIntoRows from './useImagesIntoRows'
import { ImageProps } from './Image'

interface PhotoWallProps<T extends Image> extends HtmlHTMLAttributes<HTMLDivElement> {
  entries: T[]
  maxHeight?: number
  margin?: number
  ImageComponent?: React.FC<ImageProps<T>>
  imageClassName?: string,
  imageChildren?: any,
  fillLastRow?: boolean
}

function PhotoWall<T extends Image>(props: PhotoWallProps<T>) {
  const {
    entries,
    maxHeight = 550,
    margin = 5,
    className,
    ImageComponent,
    imageClassName,
    imageChildren,
    fillLastRow,
    style,
    ...other
  } = props
  const finalClassName = 'photowall' + (className ? ' ' + className : '')
  const finalStyle = { ...style || {}, padding: margin + 'px' }

  // Measure actual width
  const totalWidth = useWidth()

  // Fit images into rows
  const layoutCfg = { entries, fillLastRow, margin, maxHeight, totalWidth }
  const rows = useImagesIntoRows(layoutCfg)
  const children = rows.map((row, i) =>
    <Row
      key={i}
      ImageComponent={ImageComponent}
      imageClassName={imageClassName}
      imageChildren={imageChildren}
      entries={row.entries}
      height={row.height}
      margin={margin}
      before={row.beforeCount}
      total={entries.length}
    />
  )

  return (
    <div className={finalClassName} style={finalStyle} {...other}>
      {children}
    </div>
  )
}

export default PhotoWall

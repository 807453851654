export const getRandomSlug = () => {
  const length = 6
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const base = charset.length

  let slug = ''
  for (let i = 0; i < length; i++) {
    slug += charset.charAt(Math.floor(Math.random() * base))
  }
  return slug
}

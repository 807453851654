import { REQUEST_INIT } from 'universal-scripts'

const reducer = (state = 1200, action) => {
  if (action.type === REQUEST_INIT) {
    if (action.payload.cookies?.width) {
      return parseInt(action.payload.cookies.width)
    }
  }
  if (action.type === 'WIDTH/SET') {
    return action.payload
  }

  return state
}

export default reducer

import React from 'react'
import { FormattedDate } from 'react-intl'
import { EntryMeta } from 'src/types'
import './ImageMeta.sass'

interface ImageMetaProps {
  entry?: EntryMeta
}

const ImageMeta = ({ entry }: ImageMetaProps) => {
  if (!entry.title && !entry.date) return null
  return (
    <div className="meta">
      {entry.title &&
        <div className="title">{entry.title}</div>
      }
      {entry.date &&
        <div className="date">
          <FormattedDate
            value={entry.date}
            day="numeric"
            month="short"
            year="numeric"
          />
        </div>
      }
    </div>
  )
}

export default ImageMeta

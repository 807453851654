import React from 'react'
import Loading from 'src/components/Loading/Loading'

/*
 * This placeholder is used on Album, Tag, and maybe other details pages.
 * It gets shown during data loading and should keep the same structure and
 * size as the final page elements, to prevent flickering.
 */

interface PagePlaceholder {
  className?: string
}

const PagePlaceholder = ({ className }: PagePlaceholder) =>
  <>
    <header className="subheader">
      <div className="left-block" />
      <div className="center-block" />
      <div className="right-block" />
    </header>
    <Loading className={'photos' + (className ? ' ' + className : '')} />
  </>

export default PagePlaceholder

import React from 'react'
import { FormattedMessage } from 'react-intl'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import useDragEvents from 'src/hooks/useDragEvents'
import { AlbumEditor } from './useEditor'
import { Image } from 'src/types'

interface AlbumPicsEditProps {
  editor: AlbumEditor
}

const AlbumPicsEdit = ({ editor }: AlbumPicsEditProps) => {
  const [events, isDragging] = useDragEvents(editor.addImages)
  const entries = editor.images

  let className = 'photos album'
  if (isDragging) className += ' dragging'
  if (!entries.length) className += ' empty'

  if (!entries.length) {
    return (
      <div className={className} {...events}>
        <div className="message">
          <FormattedMessage id="drop-files" />
        </div>
      </div>
    )
  }

  return (
    <PhotoWall
      className={className}
      {...events}
      entries={editor.images}
      imageClassName="with-actions"
      imageChildren={<ImageActions editor={editor} />}
    />
  )
}

interface ImageActionsProps {
  entry?: Image
  editor: AlbumEditor
}
const ImageActions = ({ entry, editor }: ImageActionsProps) => {
  const isStarred = editor.album.cover === entry.id
  const starClassName = 'action icon icon-star' + (isStarred ? ' active' : '')
  const handleStar = () => {
    editor.setAlbum({
      ...editor.album,
      cover: isStarred ? null : entry.id
    })
  }

  return (
    <div className="image-actions">
      <button className={starClassName} onClick={handleStar} />
      <button
        className="action icon icon-bin"
        onClick={() => editor.removeImage(entry.id)}
      />
    </div>
  )
}

export default AlbumPicsEdit

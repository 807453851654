import uuid from 'uuid'
import { Image as PWImage } from 'src/components/PhotoWall/types'
import { Image } from 'src/types'

export const loadImage = (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const img = new Image()
  img.onload = () => resolve(img)
  img.onerror = (e) => reject(e)
  img.src = url
})

export type ImageFile = Image & PWImage & { file: File }

export const imageFromFile = async (file: File): Promise<ImageFile> => {
  const date = new Date(file.lastModified).toISOString()
  const title = file.name.substr(0, file.name.lastIndexOf('.'))
  const url = URL.createObjectURL(file)
  const img = await loadImage(url)
  return {
    id: uuid(),
    url,
    date,
    title,
    size: { x: img.width, y: img.height },
    file
  }
}

export type FileHandler = (files: File[] | FileList) => void

export const pickImages = (handler: FileHandler) => {
  const input = document.createElement('input')
  input.type = 'file'
  input.multiple = true
  input.accept = 'image/*'
  input.addEventListener('change', () => {
    handler(Array.from(input.files))
  })
  input.click()
}

import React, { Suspense, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Loading from '../Loading/Loading'
import SearchResults from './SearchResults'
import './Search.sass'

const Search = () => {
  const [q, setQ] = useState('')
  const handleSubmit = e => {
    e.preventDefault()
  }

  return (
    <form className="search" onSubmit={handleSubmit}>
      <span className="icon icon-search" />
      <FormattedMessage id="search.placeholder">
        {(placeholder: any) =>
          <input
            name="search"
            autoComplete="off"
            value={q}
            onChange={e => setQ(e.target.value)}
            required
            title={placeholder}
            placeholder={placeholder}
          />
        }
      </FormattedMessage>
      {q &&
        <div className="results">
          <Suspense fallback={<Loading />}>
            <SearchResults q={q} setQ={setQ} />
          </Suspense>
        </div>
      }
    </form>
  )
}

export default Search

import React from 'react'
import { Image } from './types'
import DefaultImageComponent, { ImageProps } from './Image'
import useVisible from 'src/hooks/useVisible'

interface RowProps<T extends Image> {
  entries: T[]
  height: number
  margin: number
  before: number
  total: number
  ImageComponent?: React.FC<ImageProps<T>>
  imageClassName: string
  imageChildren: any
}

function Row<T extends Image>(props: RowProps<T>) {
  const [isVisible, ref] = useVisible()
  const { entries, height, margin, before, imageClassName, imageChildren, total } = props
  const ImageComponent = props.ImageComponent || DefaultImageComponent
  const h = Math.round(height)
  return (
    <div className="row" style={{ height: h + 2 * margin + 'px' }} ref={ref}>
      {isVisible && entries.map((entry, i) => {
        const style = {
          height: h,
          width: Math.round(height / entry.size.y * entry.size.x),
          margin: margin + 'px'
        }
        const n = before + i
        return (
          <ImageComponent
            key={n}
            n={n}
            i={i}
            total={total}
            style={style}
            entry={entry}
            className={imageClassName}
            children={imageChildren}
          />
        )
      })}
    </div>
  )
}

export default Row

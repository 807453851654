import React from 'react'
import './ImageActions.sass'

interface ImageProps {
  n?: number
  total?: number
  remove: (n: number) => void
  swap: (a: number, b: number) => void
}

const ImageActions = ({ n, total, swap, remove }: ImageProps) => {
  return (
    <div className="image-actions">
      <button
        className="action move-left"
        disabled={n === 0}
        onClick={() => swap(n, n - 1)}
      />
      <button
        className="action remove"
        onClick={() => remove(n)}
      />
      <button
        className="action move-right"
        disabled={n === total - 1}
        onClick={() => swap(n, n + 1)}
      />
    </div>
  )
}

export default ImageActions

import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import { albumToPWImage, useSearchTerms, useTag, useTagCRUD } from 'src/hooks/api'
import AlbumTagged from './AlbumTagged'
import BackButton from 'src/components/BackButton'
import PagePlaceholder from 'src/components/DetailsPage/PagePlaceholder'
import './Bulk.sass'

const BulkTag = () => {
  const tagCRUD = useTagCRUD()
  const { albums, tags } = useSearchTerms()

  const { tag: slug } = useParams()
  const { tag, albums: taggedAlbums } = useTag(slug)
  const taggedSlugs = useMemo(() => taggedAlbums.map(x => x.slug), [taggedAlbums])

  const [tagged, setTagged] = useState(taggedSlugs)
  useEffect(() => {
    if (tagged !== taggedSlugs) {
      setTagged(taggedSlugs)
    }
  }, [taggedSlugs])
  const hasChanges = tagged !== taggedSlugs

  const idx = tags.findIndex(t => t.slug === slug)
  const prev = idx > 0 ? tags[idx - 1] : null
  const next = idx < tags.length ? tags[idx + 1] : null

  const entries = albums
    .map(albumToPWImage)
    .map(album => ({
      ...album,
      isTagged: tagged.includes(album.slug)
    }))

  const handleClick = (tag) => {
    if (tagged.includes(tag)) {
      setTagged(tagged.filter(x => x !== tag))
    } else {
      setTagged([...tagged, tag])
    }
  }

  const handleSave = () => {
    tagCRUD.setAlbums(slug, tagged)
    // .then(() => history.push('/tag/' + slug))
  }

  return (
    <>
      <header className="subheader">
        <div className="left-block">
          <BackButton />
        </div>
        <div className="center-block">
          <div className="album-info">
            <h1>
              {prev && <Link className="icon icon-left" to={`/tag/${prev.slug}/edit`} title={prev.title} />}
              {tag.title}
              {next && <Link className="icon icon-right" to={`/tag/${next.slug}/edit`} title={next.title} />}
            </h1>
          </div>
        </div>
        <div className="right-block">
          <button className="icon icon-save" type="button" onClick={handleSave} disabled={!hasChanges} />
          <Link className="icon icon-cancel" to={'/tag/' + slug} />
        </div>
      </header>
      <PhotoWall
        className="photos tag bulk"
        entries={entries}
        imageClassName="with-tagged"
        imageChildren={<AlbumTagged onClick={handleClick}/>}
        maxHeight={200}
      />
    </>
  )
}

const BulkTagWrapper = () =>
  <Suspense fallback={<PagePlaceholder className="tag" />}>
    <BulkTag />
  </Suspense>


export default BulkTagWrapper

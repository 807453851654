import React from 'react'
import { FormattedMessage } from 'react-intl'
import TagPicker from 'src/components/TagPicker/TagPicker'
import { AlbumEditor } from './useEditor'

interface AlbumInfoProps {
  editor: AlbumEditor
}

const AlbumInfoEdit = ({ editor }: AlbumInfoProps) => {
  const { title, tags } = editor.album
  const handleChange = e => editor.setAlbum({ ...editor.album, [e.target.name]: e.target.value })
  const handleTags = newTags => editor.setAlbum({ ...editor.album, tags: newTags })
  return (
    <div className="album-info edit">
      <FormattedMessage id="edit-album.fields.title">
        {(placeholder: any) =>
          <input
            name="title"
            value={title}
            onChange={handleChange}
            placeholder={placeholder}
            required
          />
        }
      </FormattedMessage>
      <TagPicker value={tags || []} onChange={handleTags} />
    </div>
  )
}

export default AlbumInfoEdit

import { useMemo } from 'react'
import { Image } from './types'

export interface RowDef {
  entries: Image[]
  height: number
  beforeCount: number
  beforeHeight: number
}

export interface LayoutCfg {
  entries: Image[]
  fillLastRow: boolean
  margin: number
  maxHeight: number
  totalWidth: number
}

const useImagesIntoRows = (cfg: LayoutCfg): RowDef[] => {
  const { entries, fillLastRow, margin, maxHeight, totalWidth } = cfg
  return useMemo(() => {
    // Fit images into rows
    const rows: RowDef[] = []
    let row: Image[] = []
    let rowWidth: number = 0
    let beforeCount: number = 0
    let beforeHeight: number = 0
    for (const entry of entries) {
      row.push(entry)
      rowWidth += maxHeight / entry.size.y * entry.size.x
      if (rowWidth >= totalWidth) {
        const availableWidth = totalWidth - 2 * margin * (row.length + 1)
        const rowHeight = maxHeight * availableWidth / rowWidth
        rows.push({
          entries: row,
          height: rowHeight,
          beforeCount,
          beforeHeight,
        })
        beforeCount += row.length
        beforeHeight += rowHeight
        row = []
        rowWidth = 0
      }
    }
    if (row.length) {
      if (fillLastRow) {
        // Fill the last row even if we exceed the max height
        const availableWidth = totalWidth - 2 * margin * (row.length + 1)
        const rowHeight = maxHeight * availableWidth / rowWidth
        rows.push({
          entries: row,
          height: rowHeight,
          beforeCount,
          beforeHeight,
        })
      } else {
        rows.push({
          entries: row,
          height: maxHeight,
          beforeCount,
          beforeHeight,
        })
      }
    }
    return rows
  }, [entries, fillLastRow, margin, maxHeight, totalWidth])
}

export default useImagesIntoRows

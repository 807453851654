import { RefObject, useEffect, useRef, useState } from 'react'

const useVisible = (): [boolean, RefObject<any>] => {
  const [isVisible, setVisible] = useState(false)
  const ref = useRef<any>()

  useEffect(() => {
    const opts = { rootMargin: '100px' }
    const handler: IntersectionObserverCallback = entries => setVisible(entries.some(entry => entry.isIntersecting))
    const observer = new IntersectionObserver(handler, opts)
    if (ref.current) observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return [isVisible, ref]
}

export default useVisible

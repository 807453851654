import React from 'react'
import { useParams } from 'react-router-dom'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import { imageToPWImage, useAlbum } from 'src/hooks/api'

const AlbumPics = () => {
  const { slug } = useParams()
  const images = useAlbum(slug)?.images || []
  const entries = images.map(imageToPWImage)
  return (
    <PhotoWall
      className="photos album"
      entries={entries}
    />
  )
}

export default AlbumPics

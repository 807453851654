import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Image as PWImage } from 'src/components/PhotoWall/types'
import { albumToPWImage, imageToPWImage, useAlbum, useAlbumCRUD } from 'src/hooks/api'
import { AlbumDetails, Tag } from 'src/types'
import { ImageFile, imageFromFile } from 'src/util/image'
import { getRandomSlug } from 'src/util/slug'

export interface AlbumEditor {
  editing: boolean
  album: EditedAlbum
  images: PWImage[]
  isModified: boolean
  isLoading: boolean
  setAlbum: (newAlbum: EditedAlbum) => void
  addImages: (files: File[]) => void
  removeImage: (id: string) => void
  edit: () => void
  save: () => void
  cancel: () => void
}

interface EditedAlbum {
  slug: string
  title: string
  cover?: string
  tags: Tag[]
}

interface EditedImages {
  toAdd: ImageFile[]
  toDelete: string[]
}

const albumToEditedAlbum = (info?: AlbumDetails): EditedAlbum =>
  info
  ? {
    slug: info.album.slug,
    title: info.album.title,
    cover: info.album.cover?.id,
    tags: info.album.tags
  }
  : {
    slug: getRandomSlug(),
    title: '',
    tags: []
  }

const useEditor = (slug: string, editing: boolean): AlbumEditor => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentInfo = useAlbum(slug)
  const albumCRUD = useAlbumCRUD()
  const initialAlbum = useMemo(() => albumToEditedAlbum(currentInfo), [currentInfo])
  const [album, setAlbum] = useState<EditedAlbum>(initialAlbum)
  const [images, setImages] = useState<EditedImages>({ toDelete: [], toAdd: [] })
  const isModified = album !== initialAlbum || images.toAdd.length > 0 || images.toDelete.length > 0
  const [isLoading, setLoading] = useState(false)

  // Prepare the image mix:
  const processedImages = [
    ...(currentInfo?.images?.filter(i => !images.toDelete.includes(i.id)).map(imageToPWImage) || []),
    ...images.toAdd
  ]
  processedImages.sort((a, b) => a.date > b.date ? 1 : -1)

  const canonical = currentInfo ? albumToPWImage(currentInfo.album).href : '/albums/new'

  return {
    editing,
    album,
    images: processedImages,
    isModified,
    isLoading,
    setAlbum: (newAlbum: EditedAlbum) => {
      setAlbum(newAlbum)
    },
    addImages: async (files) => {
      for (const file of files) {
        const img = await imageFromFile(file)
        setImages(x => ({ ...x, toAdd: [...x.toAdd, img] }))
      }
    },
    removeImage: (id) => {
      if (images.toAdd.some(i => i.id === id)) {
        setImages(x => ({ ...x, toAdd: x.toAdd.filter(i => i.id !== id) }))
      } else {
        setImages(x => ({ ...x, toDelete: [ ...x.toDelete, id ] }))
      }
    },
    edit: () => {
      history.replace(canonical + '/edit')
    },
    save: async () => {
      setLoading(true)
      const dataToSend = {
        ...album,
        tags: album.tags.map(tag => tag.slug),
        deletedImages: images.toDelete
      }
      const body = new FormData()
      body.append('album', JSON.stringify(dataToSend))
      images.toAdd.forEach(img =>
        body.append('image', img.file)
      )

      const result: any = slug ? await albumCRUD.update(slug, body) : await albumCRUD.create(body)
      setAlbum(albumToEditedAlbum(result))
      setImages({ toDelete: [], toAdd: [] })
      setLoading(false)
      dispatch({
        type: 'useFetch/success',
        key: 'album/' + result.album.slug,
        value: result
      })
      const newCanonical = albumToPWImage(result.album).href
      history.replace(newCanonical)
    },
    cancel: () => {
      setAlbum(albumToEditedAlbum(currentInfo))
      setImages({ toDelete: [], toAdd: [] })
      if (slug) {
        history.replace(canonical)
      } else {
        history.replace('/')
      }
    }
  }
}

export default useEditor

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Tag } from 'src/types'

interface TagResultProps {
  entry: Tag
  onSelect?: () => void
}

const TagResult = ({ entry, onSelect }: TagResultProps) => {
  return (
    <Link className="search-result tag" to={'/tag/' + entry.slug} onClick={onSelect}>
      <div className={'preview icon icon-tag tag-type-' + entry.type} />
      <div className="info">
        <div className="title">
          {entry.title}
        </div>
        <div className="subtitle">
          <FormattedMessage id="search.tag-albums" values={{ n: entry.albums }} />
        </div>
      </div>
    </Link>
  )
}

export default TagResult

import React, { Suspense, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSearchTerms } from 'src/hooks/api'
import { Tag } from 'src/types'
import { tagMatch } from '../Search/searchMatch'
import './TagPicker.sass'

interface TagPickerProps {
  value: Tag[]
  onChange: (newValue: Tag[]) => void
}

const TagPicker = ({ value, onChange }: TagPickerProps) => {
  const [search, setSearch] = useState('')
  const [rawSel, setSel] = useState(0)
  const inputRef = useRef<HTMLInputElement>()

  const allTags: Tag[] = useSearchTerms().tags
  const results: Tag[] = allTags
    .filter(tagMatch(search))
    .filter(tag => !value.some(t => t.slug === tag.slug))

  const sel = rawSel < results.length ? rawSel : results.length - 1

  const handleAdd = (tag: Tag) => {
    setSel(0)
    setSearch('')
    onChange([ ...value, tag ])
    setTimeout(() => inputRef.current?.focus(), 0)
  }

  const handleRemove = (tag: Tag) => {
    onChange(value.filter(x => x.slug !== tag.slug))
  }

  const handleKeyDown = e => {
    if (e.code === 'ArrowDown') {
      e.preventDefault()
      setSel(Math.min(sel + 1, results.length - 1))
    } else if (e.code === 'ArrowUp') {
      e.preventDefault()
      setSel(sel > 0 ? sel - 1 : 0)
    } else if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault()
      if (results[sel]) {
        handleAdd(results[sel])
      }
    } else if (e.code === 'Backspace' && search === '') {
      onChange(value.slice(0, -1))
    }
  }

  return (
    <div className="tag-picker">
      {value.map(tag =>
        <div className={'tag tag-type-' + tag.type} key={tag.slug}>
          #{tag.title}
          <span className="remove" onClick={() => handleRemove(tag)}/>
        </div>
      )}
      <FormattedMessage id="edit-album.fields.tags">
        {(placeholder: any) =>
          <input
            className="tag-add"
            placeholder={placeholder}
            value={search}
            onChange={e => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={() => setSel(0)}
            ref={inputRef}
          />
        }
      </FormattedMessage>
      <div className="options">
        {results.map((tag, i) =>
          <div className={'option' + (i === sel ? ' selected' : '')} onMouseDown={() => handleAdd(tag)} key={tag.slug}>
            <span className={'hashtag tag-type-' + tag.type} />
            <span className="label">{tag.title}</span>
          </div>
        )}
        {results.length === 0 &&
          <div className="option none">
            No results
          </div>
        }
      </div>
    </div>
  )
}

const LoadingTagPicker = ({ value }: TagPickerProps) => {
  return (
    <div className="tag-picker">
      {value.map(tag =>
        <div className={'tag tag-type-' + tag.type} key={tag.slug}>
          #{tag.title}
          <span className="remove" />
        </div>
      )}
    </div>
  )
}

const TagPickerWrapper = (props) =>
  <Suspense fallback={<LoadingTagPicker {...props} />}>
    <TagPicker {...props} />
  </Suspense>


export default TagPickerWrapper

import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect, useParams } from 'react-router-dom'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import { albumToPWImage, useTag } from 'src/hooks/api'
import ImageMeta from '../Home/ImageMeta'
import BackButton from 'src/components/BackButton'
import PagePlaceholder from 'src/components/DetailsPage/PagePlaceholder'

const Tag = () => {
  const { tag: slug } = useParams()
  const { tag, albums } = useTag(slug)
  const entries = albums.map(albumToPWImage)

  return (
    <>
      <header className="subheader">
        <div className="left-block">
          <BackButton />
        </div>
        <div className="center-block">
          <div className="album-info">
            <h1>{tag.title}</h1>
          </div>
        </div>
        <div className="right-block">
          <Link className="icon icon-edit" to={'/tag/' + slug + '/edit'} />
        </div>
      </header>
      <PhotoWall
        className="photos tag"
        entries={entries}
        imageClassName="with-meta"
        imageChildren={<ImageMeta />}
      />
    </>
  )
}

const TagWrapper = () => {
  const isLoggedIn = useSelector((s: any) => !!s.user)
  if (!isLoggedIn) return <Redirect to="/" />

  return (
    <Suspense fallback={<PagePlaceholder className="tag" />}>
      <Tag />
    </Suspense>
  )
}

export default TagWrapper

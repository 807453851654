import React from 'react'
import { EntryMeta } from 'src/types'

interface AlbumTaggedProps {
  entry?: EntryMeta & { slug: string, isTagged: boolean }
  onClick: (tag: string) => void
}

const AlbumTagged = ({ entry, onClick }: AlbumTaggedProps) => {
  return (
    <div
      className={'album-tagged ' + (entry.isTagged ? 'tagged' : 'untagged')}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        onClick(entry.slug)
      }}
    />
  )
}

export default AlbumTagged

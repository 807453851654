import React from 'react'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet-async'

const Meta = () => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: 'site.title' })
  const description = intl.formatMessage({ id: 'site.description' })

  return (
  <Helmet>
    <title>{title}</title>
    <meta property="og:description" content={description} />
    <link rel="icon" type="image/png" href="/favicon.png" />
  </Helmet>
  )
}

export default Meta

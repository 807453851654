import React, { Suspense } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import BackButton from 'src/components/BackButton'
import PagePlaceholder from 'src/components/DetailsPage/PagePlaceholder'
import useEditor from './useEditor'
import AlbumInfo from './AlbumInfo'
import AlbumInfoEdit from './AlbumInfoEdit'
import ActionButtons from './ActionButtons'
import AlbumPics from './AlbumPics'
import AlbumPicsEdit from './AlbumPicsEdit'
import './Album.sass'

const Album = () => {
  const { slug } = useParams()
  const { pathname } = useLocation()
  const editing = pathname === '/album/new' || pathname.split('/')[4] === 'edit'
  const editor = useEditor(slug, editing)

  const HeaderTag = editing ? 'form' : 'header'

  const handleSubmit = e => {
    e.preventDefault()
    editor.save()
  }

  return (
    <>
      <HeaderTag className="subheader" onSubmit={handleSubmit}>
        <div className="left-block">
          <BackButton />
        </div>
        <div className="center-block">
          {editing ? <AlbumInfoEdit editor={editor} /> : <AlbumInfo /> }
        </div>
        <div className="right-block">
          <ActionButtons editor={editor} />
        </div>
      </HeaderTag>
      {editing ? <AlbumPicsEdit editor={editor} /> : <AlbumPics /> }
    </>
  )
}

const AlbumWrapper = () =>
  <Suspense fallback={<PagePlaceholder className="album" />}>
    <Album />
  </Suspense>

export default AlbumWrapper

import width from './reducers/width'
declare const __SERVER__: boolean

const user = __SERVER__
  ? require('./reducers/user.server').default
  : require('./reducers/user.client').default

export const reducerList = {
  user,
  width,
}

export default reducerList

import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import PhotoWall from 'src/components/PhotoWall/PhotoWall'
import { albumToPWImage, useAlbums } from 'src/hooks/api'
import ImageMeta from './ImageMeta'
import Loading from 'src/components/Loading/Loading'
import './Home.sass'

const Home = () => {
  const albums = useAlbums()
  const entries = albums.map(albumToPWImage)
  const first = entries[0]

  return (
    <>
      {first &&
        <Helmet>
          <meta property="og:image" content={first.url} />
          <meta property="og:image:width" content={first.size.x} />
          <meta property="og:image:height" content={first.size.y} />
        </Helmet>
      }
      <PhotoWall
        className="photos home"
        entries={entries}
        imageClassName="with-meta"
        imageChildren={<ImageMeta />}
      />
    </>
  )
}

const HomeWrapper = () =>
  <Suspense fallback={<Loading className="photos home" />}>
    <Home />
  </Suspense>


export default HomeWrapper

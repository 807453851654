 export default ({ dispatch }) => {

  const handler = () => {
    const width = document.body.clientWidth
    dispatch({ type: 'WIDTH/SET', payload: width })
    document.cookie = `width=${width};max-age=31536000`
  }
  window.addEventListener('resize', handler)
  setTimeout(handler, 100)

  return (next) => (action) => next(action)
}

import React from 'react'
import { useSelector } from 'react-redux'
import Loading from 'src/components/Loading/Loading'
import { Store } from 'src/types'
import { AlbumEditor } from './useEditor'

interface ActionButtonsProps {
  editor: AlbumEditor
}

const ActionButtons = ({ editor }: ActionButtonsProps) => {
  const loggedIn = useSelector((s: Store) => !!s.user)
  if (!loggedIn) return null
  if (!editor.editing) {
    return <button className="icon icon-edit" type="button" onClick={editor.edit} />
  } else if (editor.isLoading) {
    return <Loading className="tiny" />
  } else {
    return (
      <>
        <button className="icon icon-save" type="submit" disabled={!editor.isModified} />
        <button className="icon icon-cancel" type="button" onClick={editor.cancel} />
      </>
    )
  }
}

export default ActionButtons

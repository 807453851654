import React from 'react'
import { useHistory } from 'react-router-dom'

const BackButton = () => {
  const history = useHistory()
  const handleBack = e => {
    e.preventDefault()
    if (history.action === 'PUSH') {
      history.go(-1)
    } else {
      history.replace('/')
    }
  }
  return (
    <a href="/" className="icon icon-back" onClick={handleBack} />
  )
}

export default BackButton

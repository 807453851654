import { useFetch, useCRUD, useFetchCb } from '@gluedigital/ruse-fetch-extras'
import { AlbumDetails, AlbumWithCover, Image, SearchInfo, Tag, TagDetails } from 'src/types'
import { Image as PWImage } from 'src/components/PhotoWall/types'

const API_URL = (process.env.APP_URL || 'http://localhost:3000') + '/api'
const UPLOADS_URL = (process.env.CDN_URL || process.env.APP_URL || 'http://localhost:3000') + '/uploads'

// Suspense-able fetchers

export const useAlbums = (): AlbumWithCover[] =>
  useFetch<any[]>(API_URL + '/albums', undefined, { key: 'albums', keep: true })

export const useAlbum = (slug?: string): AlbumDetails =>
  useFetch<any>(slug && (API_URL + '/albums/' + slug), undefined, { key: 'album/' + slug, keep: true })

export const useSearchTerms = (): SearchInfo =>
  useFetch<any>(API_URL + '/search', undefined, { key: 'search', keep: true })

export const useTags = (): Tag[] =>
  useFetch<any>(API_URL + '/tags', undefined, { key: 'tags', keep: true })

export const useTag = (tag: string): TagDetails =>
  useFetch<any>(API_URL + '/tags/' + tag, undefined, { key: 'tag/' + tag, keep: true })

// --- CRUD endpoints ---

export const useAlbumCRUD = () => useCRUD(API_URL + '/albums')
export const useImageCRUD = () => useCRUD(API_URL + '/images')
export const useTagCRUD = () => {
  const fetch = useFetchCb()
  const crud = useCRUD(API_URL + '/tags')
  const reloadTag = (tag) => fetch(API_URL + '/tags/' + tag, undefined, { key: 'tag/' + tag, keep: true })
  return {
    ...crud,
    setAlbums: (tag: string, albums: string[]) =>
      crud.custom(`/${tag}/albums`, { method: 'PUT', body: albums })
        .then(() => reloadTag(tag))
  }
}

// --- Some Utils ---

export const albumToPWImage = <T extends AlbumWithCover> (album: T): (T & PWImage) => ({
  ...album,
  url: album.cover ? `${UPLOADS_URL}/${album.cover.id}.jpg` : '/placeholder.png',
  size: album.cover ? album.cover.size : { x: 800, y: 600 },
  href: '/album/' + album.slug + '/' + album.title.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9\-]/g, ''),
})

export const imageToPWImage = (image: Image): (Image & PWImage) => ({
  ...image,
  url: `${UPLOADS_URL}/${image.id}.jpg`
})

export const tagToPWImage = (tag: Tag): (Tag & PWImage) => ({
  ...tag,
  url: null,
  size: { x: 300, y: 400 },
  href: '/tag/' + tag.slug
})

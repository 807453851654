import React from 'react'
import { useIntl } from 'react-intl'
import { useParams, useLocation, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { albumToPWImage, useAlbum } from 'src/hooks/api'

const AlbumMeta = () => {
  const { slug } = useParams()
  const { album, images } = useAlbum(slug)
  const intl = useIntl()

  const description = intl.formatMessage({ id: 'album.description' }, {
    date: intl.formatDate(album.date, { day: 'numeric', month: 'short', year: 'numeric'}),
    total: images.length
  })

  const { pathname } = useLocation()
  const { href: canonical, size, url: cover  } = albumToPWImage(album)

  if (pathname !== canonical) return <Redirect to={canonical} />

  return (
    <Helmet>
      <title>{album.title}</title>
      <meta property="og:description" content={description} />
      <meta property="og:image" content={cover} />
      <meta property="og:image:width" content={size.x} />
      <meta property="og:image:height" content={size.y} />
    </Helmet>
  )
}

export default AlbumMeta
